export const LEASE_SIGNING_FLOW_STRINGS = {
  // Wallet step
  'wallet.label': 'Wallet',
  'wallet.title': 'Build towards homeownership as you <span>rent with Belong.</span>',
  'wallet.subtitle':
    'At Belong, no good deed goes unappreciated. A portion of on-time rent payments and more will accumulate in your new Karma\u00a0Wallet.',
  'wallet.features.karma.title': 'Karma Wallet',
  'wallet.features.karma.description':
    'Over time, that Karma may give you priority access to future homes you want to\u00a0rent.',
  'wallet.features.approved.title': 'You’re approved!',
  'wallet.features.approved.description':
    'When you’re ready, you’ll be able to apply Karma Dollars you’ve earned towards the purchase of eligible Belong\u00a0homes.',
  'wallet.features.hi.title': 'Hi there!',
  'wallet.features.hi.description':
    'You’ll get your very own licensed Home Purchase Specialist, who will guide you through the search, negotiate on your behalf, and help you\u00a0close.',
  'wallet.features.congrats.title': 'So congrats!',
  'wallet.features.congrats.description': 'You’re well on your way to\u00a0homeownership!',
  'wallet.tip.title': 'Treating Everyone As An\u00a0Owner',
  'wallet.tip.description':
    'At Belong, we believe both Homeowners and Residents should be treated with love and care, as they in turn treat their homes. One day, you may be a Belong Homeowner. We’ll provide you with an amazing experience then, just as you’ll receive now as a Belong\u00a0Resident.',
  'wallet.terms-and-conditions': 'Terms and conditions apply.',
  'wallet.see-terms': 'SEE TERMS',

  // Wear & Tear step
  'wearAndTear.label': 'Wear & Tear',
  'wearAndTear.title': 'With Belong, go ahead, feel\u00a0at\u00a0home.',
  'wearAndTear.features.frames': 'Hang Your Frames',
  'wearAndTear.features.tv': 'Mount Your TV',
  'wearAndTear.features.walls': 'Paint Your Walls',
  'wearAndTear.features.plants': 'Plant Your Plants',
  'wearAndTear.description.pro': 'You can even book a Pro to do it for you!',
  'wearAndTear.description': `At the end of your time in this home, we’ll send a Pro to put your home back in its original condition. Charges may apply, but never for normal `,
  'wearAndTear.tip.title': 'Want help getting\u00a0setup?',
  'wearAndTear.tip.description':
    'Once you’re in your new home, you’ll be able to book a Pro anytime for handiwork and maintenance. You’re even welcome to request\u00a0upgrades!',

  // Deposit step
  'deposit.label': 'Deposit',
  'deposit.title': `Alright! You qualify to go deposit-free!`,
  'deposit.renewal.title': `Alright! You qualify to get your deposit\u00a0back!`,
  'deposit.subtitle':
    'Because you’re you, you have the option to save or invest what you would have put down with the deposit\u00a0instead.',
  'deposit.renewal.subtitle':
    'Because you’re you, you now have the option to receive your full security deposit back immediately, and put it to good\u00a0use.',
  'deposit.tip.title': 'Wait, I have a\u00a0choice?',
  'deposit.tip.description':
    'You sure do! Depending on your financial situation, and what may be on your horizon, you get to decide whether to put down a security deposit or not. As it should\u00a0be.',
  'deposit.accordion.waiting.title': 'Waiting for your last deposit?',
  'deposit.accordion.waiting.description':
    'Choose the Deposit-Free Monthly Fee, so you don’t need to rely on your\u00a0previous landlord.',
  'deposit.accordion.invest.title': 'Looking to invest?',
  'deposit.accordion.invest.description':
    'Choose the Deposit-Free Monthly Fee, and earn a return on funds that would otherwise be sitting\u00a0in a deposit.',
  'deposit.accordion.expense.title': 'Big expense coming up?',
  'deposit.accordion.expense.description':
    'Choose the Deposit-Free Monthly Fee, and use the cash you would have otherwise put\u00a0down.',
  'deposit.renewal.accordion.expense.description':
    'Choose the Deposit-Free Monthly Fee, and use the cash you get back\u00a0immediately.',
  'deposit.accordion.savings.title': 'Building your savings?',
  'deposit.accordion.savings.description':
    'Choose the Deposit-Free Monthly Fee, and maintain a healthy balance torwards your rainy\u00a0day fund.',
  'deposit.accordion.none.title': 'None of these apply?',
  'deposit.accordion.none.description':
    'Choose the traditional Refundable Deposit, and get it back at the end, minus charges beyond normal\u00a0wear & tear.',
  'deposit.renewal.accordion.none.description':
    'Choose to remain with the traditional Refundable Deposit, and get it back at the end, minus charges beyond normal\u00a0wear & tear.',
  'deposit.refundable': 'Refundable Deposit',
  'deposit.renewal.refundable.title': 'No, thanks.',
  'deposit.refundable.description':
    'Put a traditional Refundable Deposit down now, and receive it back at the end, minus the cost of repairs beyond normal\u00a0{wearAndTear}.',
  'deposit.renewal.refundable.description':
    'Keep a traditional Refundable Deposit with us, and receive it back when you move out, minus the cost of repairs beyond normal\u00a0{wearAndTear}.',
  'deposit.fee': 'Deposit-Free Monthly Fee',
  'deposit.renewal.fee.title': 'Go Deposit-Free',
  'deposit.fee.description': `Pay a monthly fee, and save or invest what you would have put down with the deposit. At the end, any damage beyond normal {wearAndTear} will be charged\u00a0separately.`,
  'deposit.renewal.fee.description': `Receive your deposit back immediately, and pay a monthly fee instead. When you move out, any damage beyond normal {wearAndTear} will be charged\u00a0separately.`,
  'deposit.fee.tooltip': `Earn 50% back in Karma Dollars per dollar spent on Belong Deposit-Free Monthly\u00a0Fees.`,

  // Duration step
  'duration.label': 'Duration',
  'duration.title': 'Last chance to lock in this rent price longer. . .',
  'duration.leaseEnd.title': 'When would you like this lease to\u00a0end?',
  'duration.leaseEnd.subtitle':
    "It's currently set to {date}. By taking a longer lease, you lock in the same price for more\u00a0months.",
  'duration.leaseEnd.date': 'Lease End Date',
  'duration.tip.title': 'Prices Tend to Go Up Over Time',
  'duration.tip.description':
    'By signing a longer lease, you get the same rate for longer. Plus you can avoid the headache and additional costs of moving!',
  'duration.recommended': 'Recommended',
  'duration.custom': 'Custom',

  // Sign step
  'sign.label': 'Sign Lease',
  // TODO: Regions: Check it
  'Sign.tips.description': {
    california: `Belong is licensed by the California Department of Real Estate (DRE License #02082345). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    seattle: `Belong is licensed by the Washington State Department of Licensing (License #21028494). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    miami: `Belong is licensed by the Florida Department of Licensing (License #CQ1065529). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    georgia: `Belong is licensed by the Georgia Department of Licensing (License #81255). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    southCarolina: `Belong is licensed by the South Carolina Department of Licensing (License #27097). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    northCarolina: `Belong is licensed by the North Carolina Real Estate Commission (License #C38631). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    texas: `Belong is licensed by the Texas Department of Real Estate (DRE License #9013886). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    utah: `Belong is licensed by the Utah Department of Real Estate (DRE License #13538686-CN00). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    colorado: `Belong is licensed by the Colorado Department of Regulatory Agencies, Division of Real Estate (IC. 100107131). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
    chicago: `Belong is licensed by the Illinois Division of Real Estate (License #478.027701). Our standard lease agreement lays out responsibilities and protections for both you and your homeowner, {firstName}.`,
  },
  'sign.title': `We can't wait to welcome you to the <span>Belong community!</span>`,
  'sign.title-renewal': `We thrilled to continue serving you in the <span>Belong community!</span>`,
  'sign.subtitle': `We just need to get some legal stuff out of the <span>way first...</span>`,
  'sign.download': 'Download All',
  'sign.checkbox': `I agree to electronically sign this lease and <span>its addendums.</span>`,
  'sign.CTA': 'Sign',
  'sign.whatsNext': 'What’s Next?',
  'sign.notarize': 'Look out for an email from Notarize to sign the lease!',
  'sign.notarizeSubtitle':
    'That is the final step to securing the home! We can’t wait to welcome you to the Belong community.',
  'sign.notarize.step1': 'Each adult resident will receive an email from Notarize.',
  'sign.notarize.step2': 'Follow the instructions to sign the lease.',
  'sign.notarize.step3':
    'Once {count} sign on Notarize, the lease is executed. Keep in mind the home is not yours until this is done.',
  'sign.soundsGood': 'Sounds Good',
  'sign.forReference': 'As a sneak peak, ',
  'sign.downloadLease': 'PREVIEW THE LEASE.',

  // Success
  'success.welcome': 'Welcome to Belong!',
  'success.download': 'Download our app to prepare to move in.',
  'success.subtitle':
    'The rest of your journey will be on our mobile app. Download to complete <strong>onboarding</strong>, submit <strong>pro service tickets</strong>, and track monthly <strong>payments</strong>.',
  'success.qr': 'Scan To Download The Belong App',
  'success.cta': 'Go to Account',
  'success.cta.download': 'Download App',
  'success.timeline.title': 'What’s Next',
  'success.timeline.submit': 'Submit Application',
  'success.timeline.approved': 'Get Approved',
  'success.timeline.download': 'Download the App',
  'success.timeline.onboarding': 'Complete Onboarding on the App',
  'success.timeline.move': 'Move In',

  // App modal
  'app.modal.title': 'Download the app to continue your experience with Belong!',
  'app.modal.subtitle':
    'The app is designed to make living in your home magical. Use it to submit pro service tickets, track monthly payments, and more.',
  shareableInfo: {
    bannerMessage: 'Share the celebration by referring a  homeowner. Earn $1,000.',
    shareableMessage:
      'I just signed a lease and I’m moving into a new home! Instead of being treated like a tenant, I get a dedicated Member Success Lead to take care of me and my homeowner. If you own a rental home, check them out!',
  },

  // Errors
  'errors.alreadySigned': 'You have already signed this lease.',
  'errors.externalLeaseSigned': 'This lease has already been signed.',
};

import { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from '@belong/ui';
import classNames from 'classnames/bind';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { maskPhoneNumber, unmaskPhoneNumber } from 'components/Input/masks';
import NewMediaUploader, { MEDIA_LABEL } from 'components/NewMediaUploader/NewMediaUploader';
import Spinner from 'components/Spinner/Spinner';
import Space from 'corecomponents/Space/Space';
import { SPACE_TYPES } from 'corecomponents/Space/spaceTypes';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import UserBasicInfo from 'models/common/UserBasicInfo';
import AccountPage from 'pages/Accounts/Pages/AccountPage/AccountPage';
import PropTypes from 'prop-types';
import { updateUserProfile, uploadProfilePhoto, fetchCurrentUserDetails } from 'store/redux/user/actions';
import {
  selectUser,
  selectIfUserFullDetailsApiWasCalled,
  selectPaymentMethodsFullObject,
} from 'store/redux/user/selectors';
import { YOUR_PROFILE } from 'strings/yourprofile';
import { required, phoneValidation, email, composeValidators } from 'utils/validation';
import styles from './Profile.module.css';

const cx = classNames.bind(styles);

const YPS = YOUR_PROFILE;

class Profile extends Component {
  state = {
    loading: true,
    media: {
      availableMedia: this.props.user.profileImageUrl ? [{ url: this.props.user.profileImageUrl }] : [],
    },
    spinner: false,
    toast: null,
  };

  async componentDidMount() {
    const { wasUserCalled, fetchCurrentUserDetails: fetchCurrentUserDetailsAction } = this.props;

    if (!wasUserCalled) {
      try {
        const user = await fetchCurrentUserDetailsAction();
        if (user.profileImageUrl) {
          this.setState({
            media: {
              availableMedia: [
                {
                  url: user.profileImageUrl,
                },
              ],
            },
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }

    this.setState({
      loading: false,
    });
  }

  getInitialValues() {
    const { user } = this.props;
    this.initialValue = user;

    return this.initialValue;
  }

  handleSubmit = async (values) => {
    const {
      updateUserProfile: updateUserProfileAction,
      uploadProfilePhoto: uploadProfilePhotoAction,
      fetchCurrentUserDetails: fetchCurrentUserDetailsAction,
    } = this.props;

    const { media } = this.state;

    this.setState({
      spinner: true,
      toast: null,
    });

    try {
      const checkValue = values;
      const [checkIfExists] = await Promise.all([updateUserProfileAction(checkValue), uploadProfilePhotoAction(media)]);

      this.setState({
        spinner: false,
        toast: YOUR_PROFILE.success,
      });

      //  Will rerender when the details are refreshed.
      // This is to stagger it.
      if (checkIfExists) {
        await new Promise((resolve) =>
          setTimeout(() => {
            resolve();
          }, 2000)
        );
      }

      await fetchCurrentUserDetailsAction();
    } catch (e) {
      this.setState({
        spinner: false,
      });
      console.error(e);
      return e;
    }
  };

  handleMedia = (media) => {
    this.setState({ media });
  };

  handleToastClose = () => {
    this.setState({
      toast: null,
    });
  };

  render() {
    const { media, loading, spinner, toast } = this.state;

    return (
      <>
        <AccountPage
          loading={loading}
          headerProps={{
            headerText: YOUR_PROFILE.title,
            subtitle: YOUR_PROFILE.subtitle,
          }}
        >
          <Form
            allowSubmitErrors
            initialValues={this.getInitialValues()}
            onSubmit={this.handleSubmit}
            bottomPositionMobile={0}
            getFormBottomBar={(formProps, nextButtonProps) => {
              return (
                <div className={cx('bottom-bar', 'flex justify-end items-center')}>
                  <FormLayout.Button
                    buttonClassNames={cx('button-container')}
                    ctaProps={{
                      label: 'Save',
                    }}
                    nextButtonWrapperProps={nextButtonProps}
                  />
                </div>
              );
            }}
            getForm={() => (
              <FormLayout>
                {spinner && <Spinner fixed />}
                <Space value={SPACE_TYPES.SM} />
                <div className={cx('your-profile-drop-zone')}>
                  <NewMediaUploader
                    availableMedia={media.availableMedia}
                    handleMedia={this.handleMedia}
                    mediaLabel={MEDIA_LABEL.SELFIE}
                    isSingleMedia
                  />
                </div>
                <Space value={SPACE_TYPES.XL} />
                <Row>
                  <Col md={6}>
                    <Field
                      name="firstName"
                      validate={required}
                      component={InputFinalFormAdapter}
                      placeholder={YPS.firstname}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      name="lastName"
                      validate={required}
                      component={InputFinalFormAdapter}
                      placeholder={YPS.lastname}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Field name="homeTown" component={InputFinalFormAdapter} placeholder={YPS.hometown} />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field name="aboutMe" component={InputFinalFormAdapter} placeholder={YPS.tellusaboutyourself} />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Field
                      name="email"
                      validate={email}
                      component={InputFinalFormAdapter}
                      placeholder={YPS.email}
                      disabled
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      name="phone"
                      component={InputFinalFormAdapter}
                      validate={composeValidators(phoneValidation, required)}
                      placeholder={YPS.phone}
                      mask={maskPhoneNumber}
                      unmask={unmaskPhoneNumber}
                    />
                  </Col>
                </Row>
              </FormLayout>
            )}
          />
        </AccountPage>
        {toast && (
          <Toast isVisible={Boolean(toast)} onClose={this.handleToastClose}>
            {toast}
          </Toast>
        )}
      </>
    );
  }
}

Profile.propTypes = {
  wasUserCalled: PropTypes.bool.isRequired,
  user: PropTypes.instanceOf(UserBasicInfo).isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  fetchCurrentUserDetails: PropTypes.func.isRequired,
  uploadProfilePhoto: PropTypes.func.isRequired,
};

function mapStateProps(state) {
  return {
    user: selectUser(state),
    paymentMethods: selectPaymentMethodsFullObject(state),
    wasUserCalled: selectIfUserFullDetailsApiWasCalled(state),
  };
}
const mapDispatchToProps = {
  updateUserProfile,
  fetchCurrentUserDetails,
  uploadProfilePhoto,
};

export default connect(mapStateProps, mapDispatchToProps)(Profile);
